export class GrupoSIASE {
  private _GCarrera: string;
  IdGrupo: number = 0;
  ClaveDependencia: string;
  ClaveUnidad: string;
  ClaveTipoPeriodo: string;
  ClaveTipoOferta: string;
  ClavePeriodo: string;
  ClaveNivelAcademico: string;
  ClaveGradoAcademico: string;
  ClaveModalidad: string;
  ClavePlanEstudio: string;
  ClaveInscripcion: string;
  ClaveMateria: string;
  Grupo: string;
  ClaveCarrera: string;
  ClaveEmpleado: string;
  Paquete: string;
  NumeroFase: string;
  GradoAcademico: string;
  Modalidad: string;
  PlanEstudio: string;
  Materia: string;
  Carrera: string;
  TipoInscripcion: string;
  TipoOferta: string;
  Curso: string;
  UtilizaNexus: boolean;
  CursoCompartido: boolean = false;
  CursoCompartidoIndicador: number = 0;
  MateriaEquivalente: boolean;
  VinculoGrupoSIASEEquivalente: VinculoGrupoSIASEEquivalente;
  KeyEquivalente: string;
  EnConflicto: boolean;
  Estado: boolean;

  constructor(G) {
    this.IdGrupo = G.IdGrupo ? G.IdGrupo : 0;
    this.ClaveDependencia = G.ClaveDependencia;
    this.ClaveUnidad = G.ClaveUnidad;
    this.ClaveTipoPeriodo = G.ClaveTipoPeriodo;
    this.ClaveTipoOferta = G.ClaveTipoOferta;
    this.ClavePeriodo = G.ClavePeriodo;
    this.ClaveNivelAcademico = G.ClaveNivelAcademico;
    this.ClaveGradoAcademico = G.ClaveGradoAcademico;
    this.ClaveModalidad = G.ClaveModalidad;
    this.ClavePlanEstudio = G.ClavePlanEstudio;
    this.ClaveInscripcion = G.ClaveInscripcion;
    this.ClaveMateria =  G.ClaveMateria;
    this.Grupo =  G.Grupo;
    this.ClaveCarrera =  G.ClaveCarrera;
    this.ClaveEmpleado =  G.ClaveEmpleado;
    this.Paquete =  G.Paquete;
    this.NumeroFase =  G.NumeroFase;
    this.GradoAcademico = G.GradoAcademico;
    this.Modalidad =  G.Modalidad;
    this.PlanEstudio = G.PlanEstudio;
    this.Materia =  G.Materia;
    this.Carrera =  G.Carrera;
    this.TipoInscripcion =  G.TipoInscripcion;
    this.TipoOferta = G.TipoOferta;
    this.UtilizaNexus = G.UtilizaNexus;
    this.CursoCompartido = G.CursoCompartido || false;
    this.Curso = G.Curso;
    this.EnConflicto = G.EnConflicto;
    this.Estado = G.Estado;
    this.GCarrera = G.Carrera;
    this.CursoCompartidoIndicador = G.CursoCompartidoIndicador || 0;
    this.MateriaEquivalente = G.MateriaEquivalente;
    this.VinculoGrupoSIASEEquivalente = new VinculoGrupoSIASEEquivalente(G.VinculoGrupoSIASEEquivalente);//this.MateriaEquivalente ? new VinculoGrupoSIASEEquivalente(G.VinculoGrupoSIASEEquivalente) : null;
    this.KeyEquivalente = Object.values(this.VinculoGrupoSIASEEquivalente).join('');
  }

  set GCarrera(C: string) {
    this._GCarrera = this.CarreraText(C);
  }
  get GCarrera(): string {
    return this._GCarrera;
  }
  public CarreraText(C: string): string {
    return (this.Carrera === '' || this.ClaveCarrera === '00') ? 'No Disponible' : this.Carrera;
  }
}

export class VinculoGrupoSIASEEquivalente {
  ClaveNivelAcademicoEquivalente: string;
  ClaveGradoAcademicoEquivalente: string;
  ClaveModalidadEquivalente: string;
  ClavePlanEstudioEquivalente: string;
  ClaveMateriaEquivalente: string;
  NumeroFaseEquivalente: string;
  GrupoEquivalente: string;
  PaqueteEquivalente: string;

  constructor(data?) {
    data = data ? data : {};
    this.ClaveNivelAcademicoEquivalente = data.ClaveNivelAcademicoEquivalente;
    this.ClaveGradoAcademicoEquivalente = data.ClaveGradoAcademicoEquivalente;
    this.ClaveModalidadEquivalente = data.ClaveModalidadEquivalente;
    this.ClavePlanEstudioEquivalente = data.ClavePlanEstudioEquivalente;
    this.ClaveMateriaEquivalente = data.ClaveMateriaEquivalente;
    this.NumeroFaseEquivalente = data.NumeroFaseEquivalente;
    this.GrupoEquivalente = data.GrupoEquivalente;
    this.PaqueteEquivalente = data.PaqueteEquivalente;
  }
}
