import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONSTANTS } from 'src/app/shared/constants';
import { ApiHttpService } from '../api-http/ApiHttp.service';
import { ElementoEvaluable } from 'src/app/models/Estructura/ElementoEvaluable';

@Injectable({
  providedIn: 'root'
})
export class ScormService {
  domain = 'SCORM';
  apiURL = CONSTANTS.API_URL_SCORM;

  constructor(
    private http: HttpClient,
    private api: ApiHttpService
  ) { }

  ConsultarResultadosScorm(_CuentaId: number, _PaqueteId: string) {
    return this.http.post(this.apiURL+'/'+this.domain+'/ConsultarResultadosScorm', {
      CuentaId: _CuentaId,
      PaqueteId: _PaqueteId
    });
  }
  ActualizarCalificacionScormEstudiante(_CursoId: number, _CuentaId: number, _PaqueteId: number, _Elemento: ElementoEvaluable) {
    return this.http.post(this.apiURL+'/'+this.domain+'/ActualizarCalificacionScormEstudiante', {
      CursoId: _CursoId,
      CuentaId: _CuentaId,
      PaqueteId: _PaqueteId,
      ElementoId: _Elemento.ElementoId,
      TipoElementoId: _Elemento.TipoElementoId
    });
  }
  ActualizarCalificacionScormEstudianteAutogestivo(_CursoId: number, _CuentaId: number, _PaqueteId: number, _Elemento: ElementoEvaluable) {
    return this.http.post(this.apiURL+'/'+this.domain+'/ActualizarCalificacionScormEstudianteAutogestivo', {
      CursoId: _CursoId,
      CuentaId: _CuentaId,
      PaqueteId: _PaqueteId,
      ElementoId: _Elemento.ElementoId,
      TipoElementoId: _Elemento.TipoElementoId
    });
  }



}
