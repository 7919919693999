import { Injectable } from '@angular/core';
import { ApiHttpService } from '../api-http/ApiHttp.service';

@Injectable({
  providedIn: 'root'
})
export class MensajesService {
  public Dominio = 'Mensaje';
  private _Conversacion;
  constructor(
    private API: ApiHttpService
  ) { }

  set Conversacion(C) {
    this._Conversacion = C;
  }
  get Conversacion() {
    return this._Conversacion;
  }

  /****************************************** Conversaciones *********************************************/
  public ActualizarConversacion(_Conversacion){
    return this.API.POST(this.Dominio,"ActualizarConversacion",{
      Conversacion: _Conversacion
    });
  }

  public ConsultarConversaciones(_CursoId){
    return this.API.POST(this.Dominio,"ConsultarConversaciones",{
      CursoId: _CursoId
    });
  }

  public ConsultarConversacion(_ConversacionId) {
    return this.API.POST(this.Dominio,"ConsultarConversacion",{
      ConversacionId: _ConversacionId
    });
  }

  /******************************************* Mensajes *********************************************/
  public ActualizarMensajeConversacion(_Mensaje) {
    return this.API.POST(this.Dominio,"ActualizarMensajeConversacion",{
      MensajeConversacion: _Mensaje
    });
  }
  public ConsultarConversacionMensaje(_ConversacionId) {
    return this.API.POST(this.Dominio,"ConsultarConversacionMensaje",{
      ConversacionId: _ConversacionId
    });
  }

  /************************* Functions ********************************/

  /*
  public setCuentasConversacion(C) {
    this.CuentasConversacion = C;
  }
  public getCuentasConversacion() {
    return this.CuentasConversacion;
  }
  */

}
